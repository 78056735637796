/* eslint-disable mastery/known-imports */
import { faLinkedin } from '@masterysystems/free-brands-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCircleLocationArrow,
  faCopy,
  faFilePdf,
  faMapLocation,
  faPaw,
  faShieldCheck,
  faUserGroup,
} from '@masterysystems/pro-light-svg-icons';
import {
  faArrowDownToLine,
  faArrowTurnDown,
  faArrowUpFromLine,
  faArrowUpRightFromSquare,
  faArrowsMaximize,
  faArrowsRotate,
  faBan,
  faBars,
  faBoxOpen,
  faBuilding,
  faBullseyeArrow,
  faCalendar,
  faCalendarDays,
  faCartShopping,
  faCheck,
  faCircle,
  faCircleCaretDown,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faClone,
  faCloudArrowDown,
  faCloudArrowUp,
  faCommentDots,
  faCommentLines,
  faCommentPen,
  faCompass,
  faDash,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExpand,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowDown,
  faFileArrowUp,
  faFileExcel,
  faFileSignature,
  faFilter,
  faFilters,
  faFlag,
  faFlashlight,
  faFloppyDisk,
  faFolder,
  faGear,
  faGlobe,
  faGripVertical,
  faHandHolding,
  faHouse,
  faIndustryWindows,
  faIslandTropical,
  faLineColumns,
  faLink,
  faList,
  faLocationDot,
  faLocationPin,
  faLock,
  faMagnifyingGlass,
  faMailbox,
  faMoneyBill,
  faMoon,
  faMugHot,
  faPaperclip,
  faPen,
  faPhone,
  faPig,
  faPlay,
  faPlus,
  faRoad,
  faRoute,
  faRulerTriangle,
  faScaleUnbalancedFlip,
  faShareNodes,
  faSignalStream,
  faSparkles,
  faStop,
  faStopwatch,
  faTableColumns,
  faTemperatureList,
  faThumbsDown,
  faThumbsUp,
  faTimer,
  faTrash,
  faTrashList,
  faTriangleExclamation,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUnlockKeyhole,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserCheck,
  faUsers as faUsersGroup,
  faWarehouseFull,
  faWindowRestore,
  faWineBottle,
  faXmark,
} from '@masterysystems/pro-regular-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircleCheck,
  faCircleXmark,
  faEllipsis,
  faListTree,
  faLocationCrosshairs,
  faMicrophone,
  faPaperPlane,
  faRobot,
  faSlash,
  faCircleExclamation as faSolidCircleExclamation,
  faCircleInfo as faSolidCircleInfo,
  faCircleQuestion as faSolidCircleQuestion,
  faEnvelope as faSolidEnvelope,
  faFolder as faSolidFolder,
  faLock as faSolidLock,
  faPhone as faSolidPhone,
  faThumbsDown as faSolidThumbsDown,
  faThumbsUp as faSolidThumbsUp,
  faTriangleExclamation as faSolidTriangleExclamation,
  faTruckRampBox as faSolidTruckRampBox,
  faUser as faSolidUser,
  faSpinner,
  faTruckFlatbed,
} from '@masterysystems/pro-solid-svg-icons';

// ts-unused-exports:disable-next-line
export const iconMap = {
  angleDown: faAngleDown,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  angleUp: faAngleUp,
  arrowDown: faCaretDown,
  arrowDownSolid: faArrowDown,
  arrowLeft: faCaretLeft,
  arrowLeftSolid: faArrowLeft,
  arrowMaximize: faArrowsMaximize,
  arrowRight: faCaretRight,
  arrowRightSolid: faArrowRight,
  arrowTurnDown: faArrowTurnDown,
  arrowUp: faCaretUp,
  arrowUpSolid: faArrowUp,
  balanceScaleRight: faScaleUnbalancedFlip,
  ban: faBan,
  bars: faBars,
  boxOpen: faBoxOpen,
  building: faBuilding,
  bullsEyeArrow: faBullseyeArrow,
  calendar: faCalendarDays,
  calendarEmpty: faCalendar,
  caretCircleDown: faCircleCaretDown,
  check: faCheck,
  checkCircle: faCircleCheck,
  circle: faCircle,
  circleLocationArrow: faCircleLocationArrow,
  clipboard: faClipboard,
  clock: faClock,
  clone: faClone,
  column: faTableColumns,
  commentDots: faCommentDots,
  commentLinesRegular: faCommentLines,
  commentPen: faCommentPen,
  compass: faCompass,
  copy: faCopy,
  download: faArrowDownToLine,
  drag: faGripVertical,
  edit: faPen,
  ellipsis: faEllipsis,
  envelope: faEnvelope,
  envelopeCircleCheck: faEnvelopeCircleCheck,
  envelopeSolid: faSolidEnvelope,
  exclamationCircle: faCircleExclamation,
  exclamationCircleSolid: faSolidCircleExclamation,
  exclamationTriangle: faTriangleExclamation,
  exclamationTriangleSolid: faSolidTriangleExclamation,
  expand: faExpand,
  expandAlt: faUpRightAndDownLeftFromCenter,
  eye: faEye,
  eyeSlash: faEyeSlash,
  facility: faWarehouseFull,
  file: faFile,
  fileCloudDownload: faCloudArrowDown,
  fileCloudUpload: faCloudArrowUp,
  fileDownload: faFileArrowDown,
  fileExcel: faFileExcel,
  filePdf: faFilePdf,
  fileUpload: faFileArrowUp,
  filterSolid: faFilter,
  flag: faFlag,
  folder: faFolder,
  folderSolid: faSolidFolder,
  gear: faGear,
  globe: faGlobe,
  handHolding: faHandHolding,
  history: faClockRotateLeft,
  home: faHouse,
  industry: faIndustryWindows,
  infoCircle: faCircleInfo,
  infoCircleSolid: faSolidCircleInfo,
  islandTropical: faIslandTropical,
  lineColumnsReg: faLineColumns,
  link: faLink,
  linkedin: faLinkedin,
  linkExternal: faArrowUpRightFromSquare,
  list: faList,
  lockSolid: faSolidLock,
  locationMap: faMapLocation,
  lock: faLock,
  mailBox: faMailbox,
  mapMarker: faLocationDot,
  mapMarkerEmpty: faLocationPin,
  moon: faMoon,
  moneyBill: faMoneyBill,
  mugHotLight: faMugHot,
  order: faCartShopping,
  paperClip: faPaperclip,
  partialCheck: faDash,
  paperPlane: faPaperPlane,
  paw: faPaw,
  phone: faPhone,
  phoneSolid: faSolidPhone,
  pig: faPig,
  play: faPlay,
  plus: faPlus,
  question: faCircleQuestion,
  questionSolid: faSolidCircleQuestion,
  refresh: faArrowsRotate,
  road: faRoad,
  route: faRoute,
  robot: faRobot,
  microphone: faMicrophone,
  rulerTriangle: faRulerTriangle,
  save: faFloppyDisk,
  search: faMagnifyingGlass,
  sharedNodes: faShareNodes,
  sharpFilters: faFilters,
  shieldCheck: faShieldCheck,
  shipping: faTruckFast,
  signalStream: faSignalStream,
  signature: faFileSignature,
  slash: faSlash,
  sparkles: faSparkles,
  spinner: faSpinner,
  spotlight: faFlashlight,
  stop: faStop,
  stopWatch: faStopwatch,
  temperatureList: faTemperatureList,
  thumbsUp: faThumbsUp,
  thumbsUpSolid: faSolidThumbsUp,
  thumbsDown: faThumbsDown,
  thumbsDownSolid: faSolidThumbsDown,
  timer: faTimer,
  tracking: faLocationCrosshairs,
  trash: faTrash,
  trashList: faTrashList,
  treeList: faListTree,
  truck: faTruck,
  truckLoading: faTruckRampBox,
  truckLoadingSolid: faSolidTruckRampBox,
  truckMoving: faTruckMoving,
  truckFlatbed: faTruckFlatbed,
  unlockKeyhole: faUnlockKeyhole,
  upload: faArrowUpFromLine,
  user: faUser,
  userCheck: faUserCheck,
  userGroup: faUserGroup,
  userSolid: faSolidUser,
  usersGroup: faUsersGroup,
  verticalEllipsis: faEllipsisVertical,
  windows: faWindowRestore,
  wineBottle: faWineBottle,
  x: faXmark,
  xCircle: faCircleXmark,
};
