import * as TP from '../types';

import { gql } from '@apollo/client';
import { CarrierInfoSearchPageV2FragmentDoc } from '../fragments/carrierInfoSearchPageV2';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCarriersV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.CarriersFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type SearchCarriersV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly carriersNullableV2?: TP.Maybe<(
    { readonly __typename?: 'CarrierConnectionV2' }
    & { readonly pageInfo: (
      { readonly __typename?: 'PageInfo' }
      & Pick<TP.PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), readonly edges: ReadonlyArray<(
      { readonly __typename?: 'CarrierEdgeV2' }
      & Pick<TP.CarrierEdgeV2, 'cursor'>
      & { readonly node: (
        { readonly __typename?: 'CarrierV2' }
        & Pick<TP.CarrierV2, 'id' | 'name' | 'code' | 'scacNumber' | 'mcNumber' | 'dotNumber' | 'levelId' | 'status'>
        & { readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'CarrierAddress' }
          & Pick<TP.CarrierAddress, 'id' | 'city' | 'state'>
        )>, readonly reps?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'RepV2' }
          & Pick<TP.RepV2, 'id' | 'main'>
          & { readonly employee: (
            { readonly __typename?: 'EmployeeV2' }
            & Pick<TP.EmployeeV2, 'id' | 'firstName' | 'lastName'>
          ) }
        )>>, readonly opportunity?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'id' | 'name'>
        )>, readonly carrierServices?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CarrierServiceV2' }
          & Pick<TP.CarrierServiceV2, 'id'>
          & { readonly carrierServiceType: (
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          ) }
        )>>, readonly certifications?: TP.Maybe<ReadonlyArray<(
          { readonly __typename?: 'CarrierCertificationV2' }
          & Pick<TP.CarrierCertificationV2, 'id'>
          & { readonly carrierCertificationType: (
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id' | 'name'>
          ) }
        )>> }
      ) }
    )>, readonly errors: ReadonlyArray<(
      { readonly __typename?: 'UserError' }
      & Pick<TP.UserError, 'message'>
    )> }
  )> }
);


export const SearchCarriersV2Document = gql`
    query searchCarriersV2($filter: CarriersFilterV2, $first: Int, $last: Int, $after: String, $before: String) {
  carriersNullableV2(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CarrierInfoSearchPageV2
      }
    }
    errors {
      message
    }
  }
}
    ${CarrierInfoSearchPageV2FragmentDoc}`;

/**
 * __useSearchCarriersV2Query__
 *
 * To run a query within a React component, call `useSearchCarriersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchCarriersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCarriersV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchCarriersV2Query(baseOptions?: Apollo.QueryHookOptions<SearchCarriersV2Query, SearchCarriersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCarriersV2Query, SearchCarriersV2QueryVariables>(SearchCarriersV2Document, options);
      }
export function useSearchCarriersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCarriersV2Query, SearchCarriersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCarriersV2Query, SearchCarriersV2QueryVariables>(SearchCarriersV2Document, options);
        }
export type SearchCarriersV2QueryHookResult = ReturnType<typeof useSearchCarriersV2Query>;
export type SearchCarriersV2LazyQueryHookResult = ReturnType<typeof useSearchCarriersV2LazyQuery>;
export type SearchCarriersV2QueryResult = Apollo.QueryResult<SearchCarriersV2Query, SearchCarriersV2QueryVariables>;