import { useFlagMe226678UpdateButtonColorPalette } from '@generated/flags/ME-226678-update-button-color-palette';
import { FS_UNMASK } from '@utils/fullstory';
import cx from 'classnames';
import { HTMLProps, forwardRef } from 'react';
import {
  Button as OldButton,
  ButtonOption as OldButtonOption,
} from './oldButton';
import {
  aiStyles,
  dangerStyles,
  ghostStyles,
  outlineStyles,
  solidStyles,
} from './style.css';

type CommonHTMLProps =
  | 'autoFocus'
  | 'children'
  | 'className'
  | 'disabled'
  | 'hidden'
  | 'id'
  | 'label'
  | 'name'
  | 'onBlur'
  | 'onClick'
  | 'onKeyUp'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'readOnly'
  | 'style'
  | 'tabIndex'
  | 'title'
  | 'href'
  | 'type'
  | 'width';

export interface ButtonProps
  extends Pick<HTMLProps<HTMLButtonElement>, CommonHTMLProps> {
  option?: ButtonV2Option | OldButtonOption;
  as?: 'button' | 'span' | 'div';
  show?: boolean;
  /** Disables svg color style on hover */
  noIconColorOnHover?: boolean;
  v2?: boolean;
  v1?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

interface FullStoryProps {
  fsParent?: string;
}

interface ButtonV2Theme {
  default: Record<string, string>;
  ghost?: Record<string, string>;
  outline?: Record<string, string>;
  solid?: Record<string, string>;
  danger?: Record<string, string>;
  ai?: Record<string, string>;
}

type ButtonV2Option = keyof ButtonV2Theme;

// Combine the interfaces using intersection
// ts-unused-exports:disable-next-line
export interface ExtendedButtonProps extends ButtonProps, FullStoryProps {}

export const MIN_BUTTON_HEIGHT = 28;

export const Button = forwardRef<HTMLElement, ExtendedButtonProps>(
  (originalProps, ref) => {
    const useNewColorPalette = useFlagMe226678UpdateButtonColorPalette();
    const {
      as = 'button',
      show = true,
      option = 'default',
      ...props
    } = originalProps;

    const El = as as anyOk;

    const stylesMaps: { [key: string]: string | null } = {
      quaternary: solidStyles,
      tertiary: ghostStyles,
      secondary: solidStyles,
      danger: dangerStyles,
      primary: outlineStyles,
      ai: aiStyles,
    };
    const styles = stylesMaps[option] || outlineStyles;
    if ((props?.v2 || useNewColorPalette) && !props?.v1) {
      return (
        <>
          {show ? (
            <El
              {...props}
              className={cx(styles, FS_UNMASK, props?.className)}
              ref={ref as React.Ref<HTMLButtonElement>}
              type={props?.type || 'button'}
            >
              {props.children}
            </El>
          ) : null}
        </>
      );
    }
    return (
      <OldButton
        {...props}
        show={show}
        ref={ref as React.Ref<HTMLButtonElement>}
        option={originalProps?.option as OldButtonOption}
      >
        {props.children}
      </OldButton>
    );
  }
);
