import { isFinite } from 'lodash-es';

type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'vl' | 'vvl';

export type BreakpointObject<T> = { [key in BreakpointKey]: T };

export const breakpointMap: BreakpointObject<number> = {
  xs: 375,
  sm: 600,
  md: 800,
  lg: 1000,
  xl: 1400,
  xxl: 1800,
  vl: 2200,
  vvl: 2600,
};

const { xs, sm, md, lg, xl, xxl, vl, vvl } = breakpointMap;

const cached: Record<string, string> = {};

// use like:
// breakpoint`800` = `@media (min-width: 800px)`
// breakpoint.sm = `@media (min-width: 800px)`

export const breakpoint = ([val]: TemplateStringsArray): string => {
  if (!val) {
    return '';
  }
  if (cached[val]) {
    return cached[val] || '';
  }
  const num = Number(val);
  if (num < 1 || !isFinite(num)) {
    // eslint-disable-next-line no-console
    console.error(
      `min template string value of ${val} needs to be a number without unit.`
    );
    return '';
  }
  cached[val] = `@media (min-width: ${num}px)`;
  return cached[val] || '';
};

const matchers: BreakpointObject<string> = {
  xs: `(min-width: ${xs}px)`,
  sm: `(min-width: ${sm}px)`,
  md: `(min-width: ${md}px)`,
  lg: `(min-width: ${lg}px)`,
  xl: `(min-width: ${xl}px)`,
  xxl: `(min-width: ${xxl}px)`,
  vl: `(min-width: ${vl}px)`,
  vvl: `(min-width: ${vvl}px)`,
};

/** min-width: 400px */
breakpoint.xs = `@media ${matchers.xs}`;
/** min-width: 600px */
breakpoint.sm = `@media ${matchers.sm}`;
/** min-width: 800px */
breakpoint.md = `@media ${matchers.md}`;
/** min-width: 1000px */
breakpoint.lg = `@media ${matchers.lg}`;
/** min-width: 1400px */
breakpoint.xl = `@media ${matchers.xl}`;
/** min-width: 1800px */
breakpoint.xxl = `@media ${matchers.xxl}`;
/** min-width: 2200px */
breakpoint.vl = `@media ${matchers.vl}`;
/** min-width: 2600px */
breakpoint.vvl = `@media ${matchers.vvl}`;
