/* eslint-disable no-restricted-imports */
import { RoutePath, StringSlash } from '@app/routes';
import { ReactElement } from 'react';
import { Link as RawLink, LinkProps as RawLinkProps } from 'react-router-dom';

export type OptionalSearch = `?${string}` | '';
export type LinkTo = `${RoutePath<StringSlash, StringSlash>}${OptionalSearch}`;

export interface LinkProps extends Omit<RawLinkProps, 'to'> {
  to: LinkTo;
}

export const Link = (props: LinkProps): ReactElement => {
  return <RawLink {...props} />;
};
