import { SIDEBAR_QUERY_PARAM } from '@app/Sidebar/constants';
import { TableId } from '@components/Table';
import { TablePreferencesSettings } from '@components/Table/util/customizeTable';
import { KEY_USER_PREFERENCES } from '@utils/constants';
import { jsonStringify } from '@utils/json';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import { win } from '@utils/win';
import { isEqual, isFunction, pickBy } from 'lodash-es';
import { FC, createContext, useContext, useMemo, useState } from 'react';

type userPreferencesFn = (
  userPreferences: Partial<UserPreferencesState>
) => UserPreferencesState;

export interface AvailableRoutesReferencePreference {
  id: string;
  label: string;
  referenceType: string;
}

export interface UserPreferencesState {
  mostRecentCarrier?: string;
  sidebarOpen?: boolean;
  tablePreferences?: Record<TableId, TablePreferencesSettings[]>;
  selectedRoutePreference?: string;
  fullstoryDebug?: boolean;
  preferMilitaryTime?: boolean;
  newWindowOptOut?: boolean;
  availableRoutesReferenceVisibility?: AvailableRoutesReferencePreference[];
  /** Used internally for PR previews to point to dev or test */
  prDomain?: string;
  theme?: 'dark' | 'light';
}

type SetPrefsArg = Partial<UserPreferencesState> | userPreferencesFn;

interface UserPreferencesActions {
  setPrefs: (userPreferences: SetPrefsArg) => void;
  removePrefs: () => void;
}

const rawDefaults: UserPreferencesState = {
  mostRecentCarrier: '',
  tablePreferences: {} as Record<TableId, TablePreferencesSettings[]>,
  sidebarOpen: true,
  selectedRoutePreference: '',
  fullstoryDebug: false,
  preferMilitaryTime: false,
  theme: 'light',
  availableRoutesReferenceVisibility: [],
};

export const getInitialPrefsFromStorage = (): UserPreferencesState => {
  try {
    const fromStorage =
      getLocalStorage<UserPreferencesState>(KEY_USER_PREFERENCES) ?? {};
    const amendedFromStorage: UserPreferencesState = pickBy(
      {
        ...fromStorage,
        sidebarOpen: SIDEBAR_QUERY_PARAM ?? fromStorage.sidebarOpen ?? null,
        theme: fromStorage.theme ?? 'light',
      },
      (v) => v !== null
    );
    if (!isEqual(fromStorage, amendedFromStorage)) {
      setLocalStorage(KEY_USER_PREFERENCES, amendedFromStorage);
    }
    return {
      ...rawDefaults,
      ...amendedFromStorage,
    };
  } catch {
    return { ...rawDefaults };
  }
};

function useUserPrefs(): [UserPreferencesState, UserPreferencesActions] {
  const [statePrefs, setStoredValue] = useState<UserPreferencesState>(
    (): UserPreferencesState => getInitialPrefsFromStorage()
  );

  const setPrefs = (userPreferences: SetPrefsArg): void => {
    try {
      let rawValues = userPreferences;
      if (isFunction(userPreferences)) {
        rawValues = userPreferences(statePrefs);
      }
      // use state prefs as default, allows consumers to update a single key at a time if they wish
      const finalValues = { ...statePrefs, ...rawValues };
      setStoredValue(finalValues);
      win.localStorage.setItem(
        KEY_USER_PREFERENCES,
        jsonStringify(finalValues)
      );
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
    }
  };

  const removePrefs = (): void => {
    setPrefs(rawDefaults);
  };

  return [
    statePrefs,
    {
      setPrefs,
      removePrefs,
    },
  ];
}

interface PrefsContextType
  extends UserPreferencesState,
    UserPreferencesActions {}

const defaultPrefsCtx: PrefsContextType = {
  ...getInitialPrefsFromStorage(),
  setPrefs: () => {
    // noop
  },
  removePrefs: () => {
    // noop
  },
};

const PrefsContext = createContext<PrefsContextType>(defaultPrefsCtx);

const UserPreferences: FC = ({ children }) => {
  const [prefs, actions] = useUserPrefs();
  const value = useMemo(() => {
    return { ...prefs, ...actions };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs]);

  return (
    <PrefsContext.Provider value={value}>{children}</PrefsContext.Provider>
  );
};

const useUserPreferences = (): PrefsContextType => {
  return useContext(PrefsContext);
};

export { UserPreferences, useUserPreferences };
