import { timeMask } from '@components/FieldMasks';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { MODE } from '@env';
import { useFlagMe226688ReadableTimezones } from '@generated/flags/ME-226688-readable-timezones';
import { IS_DEV_OR_TEST_OR_PR_DOMAIN, IS_LOCAL_DEV } from '@utils/constants';
import {
  TimeStringToMsOffsetUTC,
  combineDateAndTime,
  currentTZ,
  getTimeFromDatetimeString,
  getTimeZone,
  getTimeZoneLabel,
  userLocalDateToUTC,
  utcDateToLocal,
  utcDatetimeToLocalTime,
} from '@utils/time';
import { isNumber } from 'lodash-es';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import MaskedInput from 'react-text-mask';
import { usePrevious } from 'react-use';
import { ReadOnlyField } from '../Field/ReadOnlyField';

export interface Props {
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange: (adjustedTime: string | number | null | undefined) => void;
  required?: boolean;
  timezone?: string;
  value: string | null;
  allowEmpty?: boolean;
  /** Instead of a date, output a number of ms that equals the time */
  outputMs?: boolean;
  readOnly?: boolean;
  onBlur?: (
    event: ChangeEvent<HTMLInputElement>,
    value: string | number | null | undefined
  ) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  date?: Date | undefined | null | string;
}

const etcUtc = ['Etc/UTC', 'UTC'];

/** Array of string times in 15 minute intervals, like ['00:00', '00:15', '00:30', ...] */
const getTimeList = (): Array<string> => {
  const list = [];

  for (let i = 0; i < 24; i++) {
    const hour = i.toString().length === 1 ? `0${i}` : i;
    for (let a = 0; a < 4; a++) {
      const minute = a === 0 ? `${a}0` : a * 15;
      list.push(`${hour}:${minute}`);
    }
  }
  return list;
};

export function timePropsToLabel({
  date,
  time,
  timezone,
}: {
  date: Date | undefined | null | string;
  time: string | undefined | null;
  timezone: string;
}): string | undefined {
  if (!date || !time) {
    return undefined;
  }
  const timeToMs = TimeStringToMsOffsetUTC(time) || 0;
  const combineDateAndTimeToGetATimezone = combineDateAndTime({
    date,
    time: timeToMs as fixMe,
    timeOffsetMs: 0,
    timezone,
  });
  const shortTimeZone = getTimeZone(
    combineDateAndTimeToGetATimezone,
    timezone,
    'short'
  );
  const longTimeZone = getTimeZone(
    combineDateAndTimeToGetATimezone,
    timezone,
    'long'
  );
  const timeZoneLabel = getTimeZoneLabel(longTimeZone, shortTimeZone);
  return timeZoneLabel;
}
const timeList = getTimeList() as [string];

const UP_ARROW_KEYCODE = 38;
const DOWN_ARROW_KEYCODE = 40;
const N_KEYCODE = 78;

const pickTime = (value: string, keyCode: number): string => {
  const results = timeList.filter((interval: string): anyOk => {
    const parsedTime = parseInt(interval.replace(':', ''));
    const parsedValue = parseInt(value.replace(':', ''));

    if (keyCode === UP_ARROW_KEYCODE) {
      return parsedTime > parsedValue;
    }
    if (keyCode === DOWN_ARROW_KEYCODE) {
      return parsedTime < parsedValue;
    }
    return;
  });

  if (keyCode === UP_ARROW_KEYCODE) {
    return results.length === 0 ? timeList[0] : results[0] || timeList[0];
  }
  if (keyCode === DOWN_ARROW_KEYCODE) {
    return results.length === 0
      ? timeList[timeList.length - 1] || timeList[0]
      : results[results.length - 1] || timeList[0];
  }
  return '';
};

export const Time: FC<Props> = ({
  disabled,
  id,
  name,
  onChange,
  required,
  timezone = currentTZ,
  value,
  allowEmpty,
  outputMs,
  onBlur,
  onFocus,
  placeholder,
  date,
  ...rest
}): ReactElement => {
  const [userHasPermision, permissionScope] = useHasPermissionFromContext();
  const readOnly = !userHasPermision || rest.readOnly;
  const inputNeedsValue = !allowEmpty;
  const showReadbleTimezones = useFlagMe226688ReadableTimezones();
  const hideReadbleTimezones = !showReadbleTimezones;

  const valueAsTime: string | undefined =
    value || inputNeedsValue
      ? utcDatetimeToLocalTime(value || '', isNumber(value) ? 'UTC' : timezone)
      : '';

  const [time, setTime] = useState<string | undefined>(valueAsTime);
  const [propValue, setPropValue] = useState<Maybe<string | number>>(value);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [readableTimezone, setReadableTimezone] = useState<string | undefined>(
    ''
  );

  useEffect(() => {
    if (
      !date ||
      !time ||
      !timezone ||
      hideReadbleTimezones ||
      etcUtc.includes(timezone)
    ) {
      if (IS_DEV_OR_TEST_OR_PR_DOMAIN || IS_LOCAL_DEV) {
        const reasons = [];
        if (!date) {
          reasons.push('date prop is not being passed to Time component');
        }
        if (!timezone) {
          reasons.push('timezone prop is not being passed to Time component');
        }
        if (!time) {
          reasons.push('time prop is not being passed to Time component');
        }
        if (etcUtc.includes(timezone)) {
          reasons.push('timezone prop is set to Etc/UTC');
        }
        setReadableTimezone(reasons.join(', '));
      } else {
        setReadableTimezone(undefined);
      }
    } else {
      const timeZoneLabel = timePropsToLabel({ date, time, timezone });
      setReadableTimezone(timeZoneLabel);
    }
  }, [date, time, timezone, hideReadbleTimezones]);

  const changeValue = (newValue: Maybe<string | number>): void => {
    setPropValue(newValue);
    onChange(newValue);
  };

  if (MODE !== 'production') {
    /* eslint-disable no-console */
    if (!value && inputNeedsValue) {
      console.warn(
        'Time input did not receive a value. Use allowEmpty property if you wish to pass a falsey value.'
      );
    }
    /* eslint-enable no-console */
  }

  const updateTime = (time: string | undefined): Maybe<string | number> => {
    let newValue: Maybe<string | number> = undefined;
    setTime(time);
    if (!time && allowEmpty) {
      newValue = null;
    } else if (outputMs) {
      newValue = TimeStringToMsOffsetUTC(time) ?? 0;
    } else {
      const localDate = utcDateToLocal(
        value || new Date(),
        timezone
      ).toDateString();
      const localDateTime = new Date(`${localDate} ${time}`);
      const dateTime = userLocalDateToUTC(localDateTime, timezone);
      if (dateTime && !isNaN(dateTime.getTime())) {
        newValue = dateTime.toISOString();
      }
    }
    changeValue(newValue);
    return newValue;
  };

  const handleKeyUp = (keyPress: KeyboardEvent<HTMLInputElement>): void => {
    const { keyCode } = keyPress;
    if (keyCode === N_KEYCODE) {
      updateTime(getTimeFromDatetimeString(new Date().toTimeString()));
    }
  };

  const handleKeyDown = (keyPress: KeyboardEvent<HTMLInputElement>): void => {
    const { value } = keyPress.currentTarget;
    const { keyCode } = keyPress;

    if (keyCode === UP_ARROW_KEYCODE || keyCode === DOWN_ARROW_KEYCODE) {
      updateTime(pickTime(value, keyCode));
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    if ((value === '' || value === '__:__') && inputNeedsValue) {
      updateTime('');
    } else if ((value !== '' || allowEmpty) && value.indexOf('_') === -1) {
      updateTime(value);
    }
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsFocused(false);
    const { value: eventValue } = event.currentTarget;
    const adjustedTime = eventValue.replace(/_/g, '0');
    const parsedHour = parseInt(eventValue);
    let newValue: Maybe<string | number> = value;
    if (eventValue.indexOf('_') > -1) {
      newValue =
        parsedHour < 3
          ? updateTime(`0${parsedHour}:00`)
          : updateTime(adjustedTime);
    } else if (!eventValue && inputNeedsValue) {
      newValue = updateTime('00:00');
    }
    if (outputMs) {
      newValue = TimeStringToMsOffsetUTC(adjustedTime as fixMe);
    }
    onBlur?.(event, newValue);
  };

  const prev = usePrevious(timezone);

  useEffect(() => {
    // we only want to run this update after the initial render
    // otherwise onChange is called on render, which may interfere with ideas of "touched" fields
    if (prev) {
      updateTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  useEffect(() => {
    if (value !== propValue) {
      if (!isFocused) {
        setTime(valueAsTime);
      }
      setPropValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (readOnly) {
    return <ReadOnlyField data-scope={permissionScope}>{time}</ReadOnlyField>;
  }

  return (
    <MaskedInput
      {...rest}
      autoComplete="off"
      disabled={disabled}
      id={id}
      mask={timeMask}
      name={name}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onFocus={(event): void => {
        onFocus?.(event);
        return setIsFocused(true);
      }}
      onBlur={handleBlur}
      placeholder={placeholder}
      required={required}
      showMask={inputNeedsValue}
      type="text"
      value={time}
      data-scope={permissionScope}
      defaultValue=""
      className="timeInput"
      title={readableTimezone || ''}
    />
  );
};
