/* eslint-disable no-restricted-imports */
import { LinkTo } from '@components/Link';
import { kebabCase } from 'lodash-es';
import { ReactElement } from 'react';
import {
  NavLink as RawNavLink,
  NavLinkProps as RawNavLinkProps,
} from 'react-router-dom';

interface NavLinkProps extends Omit<RawNavLinkProps, 'to' | 'id'> {
  to: LinkTo;
  /** The Fullstory id for proper tracking. The value is automatically converted to kebab-case. */
  fsId: string;
}

export const NAV_LINK_ACTIVE_CLASS_NAME = 'active';

export const NavLink = (rawProps: NavLinkProps): ReactElement => {
  const { fsId, ...props } = rawProps;
  return (
    <RawNavLink
      {...props}
      activeClassName={NAV_LINK_ACTIVE_CLASS_NAME}
      // DEPRECATED - Remove this in https://masterysys.atlassian.net/browse/ME-288462
      id={kebabCase(fsId)}
      data-fsid={kebabCase(fsId)}
    />
  );
};
