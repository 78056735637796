import {
  AutoComplete,
  Props as AutoCompleteProps,
  Shell,
} from '@components/AutoComplete';
import { AddressBriefFragment } from '@generated/fragments/addressBrief';
import { CarrierInfoSidebarV2Fragment } from '@generated/fragments/carrierInfoSidebarV2';
import { useSearchCarriersCarrierInfoSidebarV2LazyQuery } from '@generated/queries/searchCarriersCarrierInfoSidebarV2';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { Actions } from 'downshift';
import { Component, FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PRIVATE_themeObj } from '../../../../components/Theme';
import { SIDEBAR_SEARCH_STYLE } from '../style';

type ShortAddress = Pick<AddressBriefFragment, 'city' | 'state'>;

const formatAddress = (address: Maybe<ShortAddress>): string =>
  address ? `${address.city}, ${address.state}` : '???';

const renderCarrierResult: AutoCompleteProps<CarrierInfoSidebarV2Fragment>['DEPRECATEDrenderItem'] =
  ({ item, key, defaultItemStyles, getItemProps, isHighlighted, index }) => {
    const { value: carrier } = item;
    const titleString = `${carrier?.name} | ${carrier?.code} | ${formatAddress(
      carrier?.mainAddress
    )}`;
    return (
      <li
        key={key}
        css={{
          ...defaultItemStyles,
          ...{
            display: 'block',
            width: '100%',
            padding: '8px',
            textAlign: 'left',
          },
        }}
        {...getItemProps({
          item,
          index,
          key,
          style: {
            backgroundColor: isHighlighted
              ? PRIVATE_themeObj.colors.primary
              : PRIVATE_themeObj.card.background,
            color: isHighlighted ? PRIVATE_themeObj.card.background : 'inherit',
          },
        })}
        title={titleString}
        data-testid={carrier?.id ?? ''}
      >
        <span css={{ fontWeight: 600 }}>{carrier?.name}</span>
        <br />
        <span>
          {carrier?.code} | {formatAddress(carrier?.mainAddress)}
        </span>
      </li>
    );
  };

const CarrierSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const carrierSelectHandler = (
    item: Shell<CarrierInfoSidebarV2Fragment> | null,
    helpers?: Actions<Shell<CarrierInfoSidebarV2Fragment>>
  ): void => {
    if (item) {
      helpers?.clearSelection();
      helpers?.closeMenu();

      push(`/carriers/${item.id}/capacity-manager`);
    }
  };
  const [searchCarriersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCarriersCarrierInfoSidebarV2LazyQuery();
  const data = dataV2?.carriersNullableV2;

  const debouncedSearchCarriers = useDebouncedFn(searchCarriersV2, 500, []);

  const carriers = data?.edges ?? [];
  const items = carriers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  return (
    <AutoComplete<CarrierInfoSidebarV2Fragment>
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          variables: { filter: { text: inputValue }, first: 15 },
        });
      }}
      onChange={carrierSelectHandler}
      loading={loadingV2}
      items={items}
      DEPRECATEDrenderItem={renderCarrierResult}
      renderDropdownInPopper
      showSearchIcon
      css={{ height: '100%' }}
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'sidebar-carrier-search-input',
        'aria-label': 'Search Carriers',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CarrierSearch: typeof Component = withRouter(CarrierSearchBase);
