import { fetchFromGraphInternalDevOnly } from '@utils/fetchHttp';
import { jsonStringify } from '@utils/json';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { BasisTheoryTokenType } from '..';

interface BasisTheoryResponseObject {
  iFrameApiKey: string;
  tokenRequestFields: {
    type: string;
    id: string;
    mask: string;
    searchIndexes?: string[];
    fingerprintExpression: string;
    containers: string[];
  };
}
interface TokenRequestFields {
  type: string;
  id: string;
  mask: string;
  searchIndexes?: string[];
  fingerprintExpression: string;
  containers: string[];
}

interface BasisTheoryApiState {
  ['DRIVERS_LICENSE']: BasisTheoryResponseObject;
}

const defaultState: BasisTheoryApiState = {
  DRIVERS_LICENSE: {
    iFrameApiKey: '',
    tokenRequestFields: {
      type: '',
      id: '',
      mask: '',
      fingerprintExpression: '',
      containers: [],
    },
  },
};

const token = 'token';

const tokenTypeMap = {
  DRIVERS_LICENSE: 'drivers_license_id',
};

interface MetadataSecureInput {
  driverCode?: string;
}

interface HandleTokenizeKwargs {
  tokenType: BasisTheoryTokenType;
  tokenRequestFields: TokenRequestFields;
  data: fixMe;
  metadata: MetadataSecureInput;
  bt: fixMe;
}

interface BasisTheoryContextManager {
  handleTokenize: ({
    tokenType,
    tokenRequestFields,
    data,
    metadata,
  }: HandleTokenizeKwargs) => Promise<anyOk>;
  instantiateBasisTheory: (tokenType: BasisTheoryTokenType) => Promise<void>;
  bt?: anyOk;
  error?: anyOk;
  apiKey: fixMe;
}

const BasisTheoryApiContext = createContext<BasisTheoryContextManager>({
  handleTokenize: async () => ({}),
  instantiateBasisTheory: async () => {},
  bt: {},
  error: {},
  apiKey: defaultState,
});

export const BasisTheoryApiKeyProvider: FC<fixMe> = ({ children }) => {
  const [apiKey, setApiKey] = useState({
    DRIVERS_LICENSE: defaultState,
  });

  async function instantiateBasisTheory(
    tokenType: BasisTheoryTokenType
  ): Promise<void> {
    try {
      fetchFromGraphInternalDevOnly({
        qs: 'q=secureDataTokenizationConfiguration',
        body: jsonStringify({
          query: `query secureDataTokenizationConfiguration(
              $input: SecureDataTokenizationConfigurationInput!
            ) {
              secureDataTokenizationConfiguration(input: $input) {
                iFrameApiKey
                tokenRequestFields {
                  type
                  id
                  mask
                  searchIndexes
                  fingerprintExpression
                  containers
                }
              }
            }
            `,
          variables: {
            input: {
              tokenType: tokenType,
            },
          },
        }),
      }).then(async (response) => {
        const res = await response.json();
        setApiKey((state) => {
          return {
            ...state,
            [tokenType]: res?.data?.secureDataTokenizationConfiguration,
          };
        });
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  const handleTokenize = useCallback(
    async ({
      tokenType,
      tokenRequestFields,
      data,
      metadata,
      bt,
    }: HandleTokenizeKwargs) => {
      if (bt) {
        try {
          const genericToken = await bt.tokens.create({
            type: token,
            containers: tokenRequestFields?.containers,
            mask: tokenRequestFields?.mask,
            id: tokenRequestFields?.id,
            data: { [tokenTypeMap[tokenType]]: data },
            metadata: {
              ...metadata,
            },
          });
          return genericToken;
        } catch (error) {
          return new Error(error as anyOk);
        }
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const value: BasisTheoryContextManager = useMemo(
    () => ({ handleTokenize, instantiateBasisTheory, apiKey }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiKey]
  );

  return (
    <BasisTheoryApiContext.Provider value={value}>
      {children}
    </BasisTheoryApiContext.Provider>
  );
};

export const useBasisTheoryCtx = (): BasisTheoryContextManager => {
  const context = useContext<BasisTheoryContextManager>(BasisTheoryApiContext);

  if (!context) {
    throw new Error(
      'useBasisTheoryApiKey must be used within a BasisTheoryApiKeyProvider'
    );
  }
  return context;
};
