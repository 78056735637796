import { getDatetimeValue } from '@components/DatetimeValue';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { Text } from '@components/Text';
// eslint-disable-next-line no-restricted-imports
import { useViewOnly } from '@components/ViewOnly';
import {
  MassUnitTypeEnum,
  TemperatureUnitTypeEnum,
  UnitOfCurrencyEnum,
  UnitOfLengthEnum,
} from '@generated/types';
import { PopupType, getInputTheme, useInputTheme } from '@hooks/useInputTheme';
import { useTheme } from '@hooks/useTheme';
import { currentTZ } from '@utils/time';
import cx from 'classnames';
import {
  FastField,
  FieldConfig,
  FieldMetaProps,
  FieldProps,
  // eslint-disable-next-line no-restricted-imports
  Field as FormikField,
} from 'formik';
import {
  get,
  isEqual,
  isFunction,
  isNil,
  isString,
  omit,
  toNumber,
  uniqueId,
} from 'lodash-es';
import {
  AllHTMLAttributes,
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLProps,
  ReactElement,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import MaskedInput from 'react-text-mask';
import { Shell } from '../AutoComplete';
import { Checkbox } from '../Checkbox';
import { DatePicker } from '../DatePicker';
import { CurrencyDisplayType, Digit, UnitType } from '../Digit';
import { Dropdown, Props as DropdownProps } from '../Dropdown';
import { Label } from '../FieldLabel';
import { MaskFunctionExpr } from '../FieldMasks';
import { Grid } from '../Grid';
import { Icon, IconColorProp, IconProp } from '../Icon';
import { Input, Props as InputProps } from '../Input';
import { NumberValue } from '../NumberValue';
import { TextArea } from '../TextArea';
import { Time } from '../Time';
import { PositionType, Tooltip } from '../Tooltip';
import { VisuallyHidden } from '../VisuallyHidden';
import { ReadOnlyField } from './ReadOnlyField';
import { IconPosition, StatusIconWrapper } from './StatusIconWrapper';

const ControlMap = {
  Checkbox,
  Currency: Digit,
  Length: Digit,
  DEPRECATED_Datepicker: DatePicker,
  Dropdown,
  Input,
  Masked: MaskedInput,
  Mass: Digit,
  Number: Digit,
  Temperature: Digit,
  TextArea,
  DEPRECATED_Time: Time,
} as const;

type InputType = keyof typeof ControlMap | undefined;

interface FieldLabelWithDefaultTooltipProps<ItemType extends FieldDefault> {
  extraLabelProps?: HTMLProps<HTMLLabelElement>;
  fieldProps: FieldProps;
  formGroupProps: FormGroupProps<ItemType>;
  htmlFor: string;
  isTooltipVisible: boolean;
  userCanEdit: boolean;
  popupValidationIconPosition?: IconPosition;
  onStatusIconClick: FormGroupProps<unknown>['onStatusIconClick'];
  inputType: InputType;
  positionType?: PositionType;
  /** walkme prop is for customer success to place selectors for additional information popups handled by a 3rd party service called walkme  */
  walkme?: string;
}

// ts-unused-exports:disable-next-line
export const REQUIRED_FIELD_ERROR_STRING = 'Required';
const REQUIRED_FIELD_ERROR_REGEX = new RegExp(
  '^' + REQUIRED_FIELD_ERROR_STRING + '$'
);
/** The default message from yup when using something like Yup.string().required() is "FIELDNAME is required" */
const yupRequiredFieldRegex = /.*is a required field$/;
const requiredRegex = new RegExp(
  yupRequiredFieldRegex.source + '|' + REQUIRED_FIELD_ERROR_REGEX.source
);

const popupIconMap: Partial<Record<PopupType, IconProp>> = {
  success: 'checkCircle',
  // TODO: this needs to change to exclamation point icon
  warningDark: 'exclamationCircle',
  error: 'exclamationCircle',
  action: 'questionSolid',
};

const FieldLabelWithDefaultTooltip = <ItemType extends FieldDefault>({
  extraLabelProps,
  fieldProps,
  formGroupProps,
  htmlFor,
  popupValidationIconPosition,
  isTooltipVisible,
  userCanEdit,
  onStatusIconClick,
  inputType,
  positionType,
}: FieldLabelWithDefaultTooltipProps<ItemType>): JSX.Element => {
  const {
    extraInfo,
    label: labelProp,
    labelHidden,
    labelProps,
    readOnly,
    name,
    required,
    actionIcon,
  } = formGroupProps;
  const { popupType, info } = useInputTheme(formGroupProps, fieldProps);
  const { colors } = useTheme();
  const { isViewOnly } = useViewOnly();

  let labelValue: ReactNode | undefined = labelProp;
  if (isFunction(labelProp)) {
    labelValue = labelProp();
  } else if (isString(labelValue)) {
    labelValue = <span>{labelValue}&nbsp;</span>;
  }

  let popupAsteriskShown = Boolean(
    required && !readOnly && !isViewOnly && userCanEdit && labelValue
  );
  if (requiredRegex.test(fieldProps.form.errors[name] as string)) {
    popupAsteriskShown = true;
  }
  if (requiredRegex.test(get(fieldProps.form.errors, name) as string)) {
    popupAsteriskShown = true;
  }

  let iconColor = colors[popupType as keyof typeof colors];
  if (popupType === 'action') {
    iconColor = 'primary';
  }

  const label = (
    <Grid
      cols={2}
      css={{
        display:
          popupValidationIconPosition === IconPosition.inline
            ? 'block'
            : 'grid',
      }}
    >
      {labelValue && (
        <Label
          htmlFor={htmlFor}
          {...extraLabelProps}
          {...labelProps}
          onClick={
            ['Dropdown'].includes(inputType ?? '')
              ? (e): void => {
                  if (inputType === 'Dropdown') {
                    try {
                      e.preventDefault();
                      (
                        document.querySelector(`#${htmlFor} button`) as fixMe
                      )?.click();
                    } catch {
                      // noop
                    }
                  }
                }
              : undefined
          }
        >
          {labelValue}
          {popupAsteriskShown ? <Text error>*</Text> : null}
        </Label>
      )}
      <Grid css={{ justifyContent: 'flex-end' }}>
        {popupType && (
          <StatusIconWrapper
            gap={0}
            isVisible={isTooltipVisible}
            info={info}
            icon={actionIcon ?? popupIconMap[popupType]}
            iconColor={iconColor as IconColorProp}
            fieldName={name}
            iconPosition={popupValidationIconPosition}
            onIconClick={onStatusIconClick}
            fieldValue={fieldProps.field.value}
            positionType={positionType}
          />
        )}
      </Grid>
    </Grid>
  );

  const innerLabel = extraInfo ? (
    <ExtraInfoWrapper label={label} info={extraInfo} />
  ) : (
    label
  );

  return labelHidden ? (
    <VisuallyHidden>{innerLabel}</VisuallyHidden>
  ) : (
    <>{innerLabel}</>
  );
};

interface FieldStatusWrapperProps<ItemType extends FieldDefault> {
  className: string;
  extraLabelProps: HTMLProps<HTMLLabelElement> | undefined;
  extraBottomPadding?: boolean;
  fieldGroupProps: FormGroupProps<ItemType>;
  fieldProps: FieldProps<ItemType>;
  htmlFor: string;
  isTooltipVisible: boolean;
  renderControl: (fieldProps: FieldProps) => ReactNode;
  userCanEdit: boolean;
  children?: ReactNode;
  popupValidationIconPosition?: IconPosition;
  onStatusIconClick?: (val: unknown | null) => anyOk;
  inputType: InputType;
  positionType?: PositionType;
  /** walkme prop is for customer success to place selectors for additional information popups handled by a 3rd party service called walkme  */
  walkme?: string;
}

/**
 * Shows a field's status via outline, tooltip, and/or icon
 */
function FieldStatusWrapper<ItemType extends FieldDefault>({
  children,
  className,
  extraLabelProps,
  fieldGroupProps,
  fieldProps,
  htmlFor,
  isTooltipVisible,
  popupValidationIconPosition,
  renderControl,
  extraBottomPadding,
  userCanEdit,
  onStatusIconClick,
  inputType,
  positionType,
  walkme,
}: FieldStatusWrapperProps<ItemType>): JSX.Element {
  const { borderColor, popupType } = useInputTheme(fieldGroupProps, fieldProps);

  const labelElement = (
    <FieldLabelWithDefaultTooltip
      inputType={inputType}
      extraLabelProps={extraLabelProps}
      fieldProps={fieldProps}
      formGroupProps={fieldGroupProps}
      htmlFor={htmlFor}
      isTooltipVisible={isTooltipVisible}
      userCanEdit={userCanEdit}
      popupValidationIconPosition={popupValidationIconPosition}
      onStatusIconClick={onStatusIconClick}
      positionType={positionType}
    />
  );

  const wrappedContent = children ? (
    children
  ) : (
    <>
      {labelElement}
      {renderControl(fieldProps)}
    </>
  );

  return (
    <div
      data-walkme={walkme || null}
      data-popuptype={popupType}
      data-fieldnameprop={fieldProps.field.name}
      css={{
        width: '100%',
        'input, textarea, button.input': {
          borderColor,
        },
        position: 'relative',
        paddingBottom: extraBottomPadding ? 18 : 0,
      }}
      className={className}
    >
      {fieldGroupProps.inline ? (
        <Grid sm="max-content 1fr" css={{ alignItems: 'center' }}>
          {wrappedContent}
        </Grid>
      ) : (
        wrappedContent
      )}
    </div>
  );
}

export const ExtraInfoWrapper: FC<{
  label: ReactNode;
  info: ReactNode;
}> = ({ label, info }) => (
  <Grid sm="1fr min-content">
    {label}
    <Tooltip label={info}>
      <span>
        <Icon i="infoCircle" size="sm" color="text" />
      </span>
    </Tooltip>
  </Grid>
);

/** These are picked to not throw TS into a tizzy in the code below when we use the "type" of input dynamically. Add more as needed. */
interface WeakFormGroupProps
  extends Pick<
    InputProps,
    | 'autoFocus'
    | 'className'
    | 'id'
    | 'max'
    | 'min'
    | 'placeholder'
    | 'required'
    | 'type'
    | 'value'
    | 'disabled'
    | 'onKeyDown'
    | 'tabIndex'
    | 'title'
  > {
  label: anyOk;
}

type RenderNode = () => ReactNode;

interface RenderLabelProps extends HTMLProps<HTMLLabelElement> {
  meta?: FieldMetaProps<unknown>;
}

export interface FormGroupChildrenFnProps extends FieldProps {
  renderLabel: (renderLabelProps?: RenderLabelProps) => ReactNode;
  renderControl: (props?: FieldProps) => ReactNode;
  htmlFor: string;
  value?: string | number | string[];
}

type FormGroupChildrenFn = (_: FormGroupChildrenFnProps) => ReactNode;

export type FieldDefault =
  | string
  | number
  | boolean
  | Shell<string>
  | Shell<number>
  | Shell<unknown>
  | Date
  | null;
export interface FormGroupProps<OnChangeType = FieldDefault>
  extends WeakFormGroupProps,
    Pick<FieldConfig, 'validate'> {
  /** For the Dropdown input type specifically. Choose a key that will be pulled from the item object to be reflected as the formik value, instead of the entire item object */
  allowEmpty?: boolean;
  allowFractional?: boolean;
  fractionDigits?: number;
  allowNegative?: boolean;
  /** If the label needs to be visually hidden for some reason. The label will still be present in the HTML for accessibility. */
  blank?: boolean;
  /** For the Checkbox input type specifically */
  checkboxSize?: number;
  children?: ReactNode | FormGroupChildrenFn;
  /** Used to specify DatePicker display format. Defaults to 'false' which hides the year.  Explicitly set to 'true' to show the year. */
  dateFormatting?: boolean;
  debounce?: number;
  dropdownKey?: string;
  dropup?: boolean;
  extraInfo?: ReactNode;
  /** Only applies to TextArea */
  maxLengthHelper?: number;
  /**
   * Use Formik's FastField component instead of Field. Ensure you have read
   * the documentation first or you could run into unintended consequences.
   * https://jaredpalmer.com/formik/docs/api/fastfield
   */
  fast?: boolean;
  guide?: boolean;
  popupValidationIconPosition?: IconPosition;
  initialSelectedItem?: DropdownProps<unknown>['initialSelectedItem'];
  inline?: boolean;
  input?: InputType;
  /** For the Dropdown input type specifically */
  items?: DropdownProps<unknown>['items'];
  renderDropdownInPopper?: DropdownProps<unknown>['renderDropdownInPopper'];
  labelHidden?: boolean;
  labelProps?: HTMLProps<HTMLLabelElement>;
  label: ReactNode | RenderNode;
  mask?: Array<string | RegExp> | MaskFunctionExpr;
  maxLength?: number;
  maxRows?: number;
  minLength?: number;
  minRows?: number;
  name: string;
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    newVal?: Maybe<string | number>
  ) => void;
  /**
   * If you provide onChange, you need to set your field value manually. It
   * will not be updated on type or selection.
   */
  onChange?: (val: OnChangeType | null, fieldProps: FieldProps) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  resize?: boolean;
  rows?: number;
  /** @deprecated Remove this! Use `<Grid>` component to provide gap around your components, or `<Spacer/>` for declarative spacing. */
  extraBottomPadding?: true;
  showSuccessIcon?: boolean;
  /** If you want to make a field required but want to do custom required validation. Return the  REQUIRED_FIELD_ERROR_STRING constant in your custom validation function if val is falsey. */
  skipRequiredFieldValidation?: boolean;
  status?: { [key: string]: anyOk };
  /** Optional suffix for digit input. Will be appended to display string when input is not active. */
  suffix?: string;
  currencyDisplay?: CurrencyDisplayType;
  timezone?: string;
  unit?: UnitType;
  warning?: (val: OnChangeType | null) => ReactNode | string | undefined;
  actionIcon?: IconProp;
  actionTooltip?: (val: OnChangeType | null) => anyOk;
  actionTooltipVisibleOnFocus?: boolean;
  onStatusIconClick?: (val: OnChangeType | null) => anyOk;
  showUnit?: boolean;
  positionType?: PositionType;
  /** walkme prop is for customer success to place selectors for additional information popups handled by a 3rd party service called walkme  */
  walkme?: string;
  /** testId passes in field parameters for the data-testid for the available pu date open and closed fields within the load repeat - stop tabs screen */
  testId?: string;
  ['data-testid']?: string;
  date?: Date | null | string;
  /**
   * When you need to display a text different from the value
   * Example is when dealing with KVTs, the value should be the id while the text should be the name
   */
  itemToString?: (val: anyOk) => string;
  forceV1?: boolean;
}

export const FIELD_ERROR_MIN_HEIGHT = 18;
export const FIELD_LABEL_HEIGHT = 16;

export const FieldError: FC<
  Pick<FieldMetaProps<unknown>, 'error' | 'touched'>
> = ({ error, touched }) => {
  const {
    colors: { error: errColor },
  } = useTheme();

  return (
    <div
      css={{
        fontSize: 10,
        color: errColor,
        minHeight: FIELD_ERROR_MIN_HEIGHT,
        paddingTop: 2,
      }}
    >
      {error &&
        touched &&
        isString(error) &&
        error.trim() &&
        error != REQUIRED_FIELD_ERROR_STRING && (
          <span>
            <Icon i="exclamationCircle" size="sm" color="error" />
            &nbsp;{error}
          </span>
        )}
    </div>
  );
};

const coerceFieldValue = (val: Maybe<string | number>): string | number => {
  if (val === null || val === undefined) {
    return '';
  }
  return val;
};

const unitDefaults: Partial<Record<keyof typeof ControlMap, anyOk>> = {
  Currency: UnitOfCurrencyEnum.Usd,
  Temperature: TemperatureUnitTypeEnum.F,
  Mass: MassUnitTypeEnum.Lbs,
  Length: UnitOfLengthEnum.Ft,
};

export const Field = <ItemType extends FieldDefault>(
  rawProps: FormGroupProps<ItemType>
): ReactElement => {
  const {
    allowEmpty,
    blank = false,
    checkboxSize,
    children,
    className,
    dropdownKey: dropdownFormikKey,
    fast,
    initialSelectedItem,
    input: inputProp,
    items = [],
    labelProps: extraLabelProps,
    validate: rawValidate,
    popupValidationIconPosition,
    onStatusIconClick,
    extraBottomPadding,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // these props are unused here, but we do not want to forward them to other components
    warning,
    showSuccessIcon,
    status,
    positionType,
    showUnit = true,
    walkme,
    suffix,
    itemToString,
    actionTooltipVisibleOnFocus = true,
    ...props
  } = rawProps;
  props['data-testid'] = props['data-testid'] ?? props.testId;
  const [htmlForState] = useState(uniqueId('field-'));
  const htmlFor = props.id || htmlForState;
  const [userCanEdit, permissionScope] = useHasPermissionFromContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const computedPopupValidationIconPosition =
    popupValidationIconPosition ?? props.inline
      ? IconPosition.inline
      : undefined;

  const FieldComponent = useMemo(
    () => (fast ? FastField : FormikField),
    [fast]
  ) as typeof FormikField;

  const validate = async (
    val: string | number
  ): Promise<string | undefined | void> => {
    if (!props.skipRequiredFieldValidation && props.required) {
      // The idea here is that we want to allow 0 as a value for number type. We want to throw required error for blank string if string type.
      const isInvalid = [
        'Currency',
        'Number',
        'Length',
        'Temperature',
        'Mass',
      ].includes(inputProp ?? '')
        ? isNil
        : (a: anyOk): boolean => {
            if (isString(a)) {
              return !a.trim();
            }
            return !a;
          };
      if (isInvalid(val)) {
        return Promise.resolve(REQUIRED_FIELD_ERROR_STRING);
      }
    }
    return (rawValidate && rawValidate(val)) || undefined;
  };

  const { gray } = useTheme();
  const { isViewOnly } = useViewOnly();

  const propsForFormikField = {
    ...props,
    status,
    validate,
    // ⬇ checkboxes break without this
    type: (inputProp?.toString() as fixMe)?.toLowerCase(),
  };

  const FormControl: anyOk = ControlMap[inputProp || 'Input'];

  const readOnly = !userCanEdit || props.readOnly;

  // These are props that are common to the Input/TextArea/Masked text input and
  // Checkbox components:
  const getCommonInputProps = (
    fieldProps: FieldProps<anyOk>,
    rawProps: FormGroupProps<anyOk>
  ): AllHTMLAttributes<HTMLInputElement> => {
    const { field, meta } = fieldProps;
    const { popupType } = getInputTheme(rawProps, fieldProps);
    const { disabled, readOnly } = rawProps;
    const backgroundColor = disabled || readOnly ? gray[95] : gray.white;
    return {
      ...omit(props, 'label', 'extraInfo', 'labelHidden'),
      id: htmlFor,
      'aria-invalid': Boolean(meta.error),
      ...field,
      value: coerceFieldValue(field.value),
      className: cx(`validation-${popupType ?? 'none'}`),
      style: {
        background: backgroundColor,
      },
    };
  };
  let renderControl = (fieldProps: FieldProps): ReactNode => {
    return (
      <div
        css={{
          width: '100%',
          position: 'relative',
        }}
        data-fieldcontrol
      >
        {isViewOnly ? (
          <div data-testid={props.id || props.name}>
            {itemToString
              ? itemToString(fieldProps.field.value)
              : fieldProps.field.value}
          </div>
        ) : readOnly ? (
          <ReadOnlyField
            {...props}
            wrap={inputProp === 'TextArea' ? true : false}
            extraBottomPadding={extraBottomPadding}
            data-scope={permissionScope}
            data-input={inputProp}
            blank={blank}
          >
            {itemToString
              ? itemToString(fieldProps.field.value)
              : fieldProps.field.value}
          </ReadOnlyField>
        ) : (
          <FormControl
            status={status}
            data-scope={permissionScope}
            {...getCommonInputProps(fieldProps, rawProps)}
            onBlur={(e: fixMe): void => {
              setIsTooltipVisible(false);
              getCommonInputProps(fieldProps, rawProps).onBlur?.(e);
            }}
            onFocus={(e: fixMe): void => {
              setIsTooltipVisible(actionTooltipVisibleOnFocus);
              getCommonInputProps(fieldProps, rawProps).onFocus?.(e);
            }}
            data-walkme={walkme || null}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              const rawVal = e.target.value;
              let newVal: number | string | null =
                props.type === 'number' ? toNumber(rawVal) : rawVal;
              if (rawVal === '') {
                newVal = null;
              }
              if (props.onChange) {
                props.onChange(newVal as ItemType, fieldProps);
              } else {
                fieldProps.form.setFieldValue(props.name, newVal);
              }
            }}
            css={{
              background: getCommonInputProps(fieldProps, rawProps).style
                ?.background as string,
            }}
          />
        )}
      </div>
    );
  };

  if (inputProp === 'Checkbox') {
    renderControl = (fieldProps: FieldProps): ReactNode => (
      <div css={{ width: '100%' }} data-fieldcontrol>
        <FormControl
          {...getCommonInputProps(fieldProps, rawProps)}
          disabled={props.disabled || readOnly}
          css={{
            background: props.disabled && !readOnly ? gray[99] : 'initial',
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            if (props.onChange) {
              props.onChange(event.target.checked as ItemType, fieldProps);
            } else {
              fieldProps.form.setFieldValue(
                fieldProps.field.name,
                event.target.checked
              );
            }
          }}
          size={checkboxSize}
          data-scope={permissionScope}
        />
      </div>
    );
  }

  if (
    inputProp === 'Currency' ||
    inputProp === 'Length' ||
    inputProp === 'Mass' ||
    inputProp === 'Number' ||
    inputProp === 'Temperature'
  ) {
    renderControl = (fieldProps: FieldProps): ReactNode => {
      if (isViewOnly) {
        return (
          <div data-testid={props.id || props.name}>
            <NumberValue
              value={fieldProps.field.value}
              unit={props.unit || unitDefaults[inputProp]}
              suffix={suffix ?? undefined}
            />
          </div>
        );
      }

      return (
        <Digit
          {...getCommonInputProps(fieldProps, rawProps)}
          {...fieldProps.field}
          {...props}
          blank={blank}
          unit={props.unit || unitDefaults[inputProp]}
          suffix={suffix ?? undefined}
          id={htmlFor}
          onChange={(amount): void => {
            if (props.onChange) {
              props.onChange(amount as fixMe, fieldProps);
            } else {
              fieldProps.form.setFieldValue(props.name, amount);
            }
          }}
          onBlur={(e): void => {
            setIsTooltipVisible(false);
            props.onBlur?.(e);
            getCommonInputProps(fieldProps, rawProps).onBlur?.(e);
          }}
          onFocus={(e): void => {
            setIsTooltipVisible(true);
            getCommonInputProps(fieldProps, rawProps).onFocus?.(e);
          }}
          allowEmpty={allowEmpty}
          showUnit={showUnit}
        />
      );
    };
  }

  if (inputProp === 'DEPRECATED_Time') {
    renderControl = (fieldProps: FieldProps): ReactNode => {
      return (
        <ControlMap.DEPRECATED_Time
          {...fieldProps.field}
          {...omit(props, ['onBlur'])}
          timezone={
            props.timezone || fieldProps.form.values.emptyTimezone || currentTZ
          }
          date={props?.date}
          id={htmlFor}
          onChange={(dateTimeISO): void => {
            if (props.onChange) {
              props.onChange(dateTimeISO as fixMe, fieldProps);
            } else {
              fieldProps.form.setFieldValue(props.name, dateTimeISO);
            }
          }}
          allowEmpty={allowEmpty}
        />
      );
    };
  }

  // Consumers should use the DateField component instead
  if (inputProp === 'DEPRECATED_Datepicker') {
    renderControl = (fieldProps: FieldProps): ReactNode => {
      const selected = fieldProps.field.value
        ? new Date(fieldProps.field.value)
        : allowEmpty
        ? null
        : new Date();

      if (isViewOnly) {
        return (
          <div data-testid={props.id || props.name}>
            {getDatetimeValue({
              value: fieldProps.field.value,
              showYear: props.dateFormatting,
              dateOnly: true,
              timezone: props.timezone,
            })}
          </div>
        );
      }
      return (
        <ControlMap.DEPRECATED_Datepicker
          {...fieldProps.field}
          {...props}
          name={props?.name}
          id={htmlFor}
          selected={selected}
          onChange={(date: Date | null): void => {
            if (props.onChange) {
              return props.onChange(date as fixMe, fieldProps);
            } else {
              return fieldProps.form.setFieldValue(props.name, date);
            }
          }}
          forceV1={props?.forceV1 ?? false}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      );
    };
  } else if (inputProp === 'Dropdown') {
    let selectItems: Shell<unknown>[];
    const emptyObjOption = { label: '', value: undefined };
    if (allowEmpty) {
      selectItems = [
        emptyObjOption as Shell<unknown>,
        ...(items as Shell<unknown>[]),
      ];
    }
    renderControl = (fieldProps: FieldProps): ReactNode => {
      let fallbackInitial = items.find((obj) => {
        let val = obj;
        if (dropdownFormikKey) {
          val = get(obj, dropdownFormikKey);
        }
        // special case for KVTs since we add extra stuff (parse metadata and isEqual won't match exactly)
        if (get(fieldProps.field.value, '__typename') === 'KeyValue') {
          return (
            Boolean(val?.id) && val?.id === get(fieldProps, 'field.value.id')
          );
        }
        return isEqual(val, fieldProps.field.value);
      });

      if (allowEmpty && fieldProps.field.value === null) {
        fallbackInitial = emptyObjOption;
      }
      const selectedItem =
        fallbackInitial || initialSelectedItem || fieldProps.field.value;

      return isViewOnly ? (
        <div data-testid={props.id || props.name}>
          {itemToString && selectedItem
            ? itemToString(selectedItem)
            : selectedItem?.label}
        </div>
      ) : (
        <ControlMap.Dropdown
          {...props}
          label={undefined}
          id={htmlFor}
          items={allowEmpty ? selectItems : (items as Shell<unknown>[])}
          // we set the field value manually for dropdowns, which triggers
          // formik validation automatically. So we need to explicitly turn off
          // blur handlers to not incur multiple errant validation triggers.
          // a TableInForm unit test covers this situation
          {...omit(fieldProps.field, ['onBlur'])}
          inputProps={{
            placeholder: props.placeholder,
          }}
          selectedItem={selectedItem}
          aria-invalid={Boolean(fieldProps.meta.error).toString()}
          onChange={(selected): void => {
            let val = selected;
            if (dropdownFormikKey) {
              val = get(selected, dropdownFormikKey) || null;
            }
            // We only call onChange for consumers, NOT setFieldValue.
            // This decreases bugs + race conditions.
            if (props.onChange) {
              return props.onChange(val as ItemType, fieldProps);
            }
            fieldProps.form.setFieldValue(props.name, val);
          }}
          itemToString={itemToString}
        />
      );
    };
  }

  if (isFunction(children)) {
    return (
      <FieldComponent {...propsForFormikField}>
        {(fieldProps: FieldProps): ReactNode => (
          <FieldStatusWrapper<ItemType>
            walkme={walkme}
            inputType={inputProp}
            className={className ?? ''}
            extraLabelProps={extraLabelProps}
            fieldGroupProps={rawProps}
            fieldProps={fieldProps}
            htmlFor={htmlFor}
            isTooltipVisible={isTooltipVisible}
            renderControl={renderControl}
            userCanEdit={userCanEdit}
            popupValidationIconPosition={computedPopupValidationIconPosition}
            onStatusIconClick={onStatusIconClick as fixMe}
            extraBottomPadding={extraBottomPadding}
            positionType={positionType}
          >
            {children({
              ...fieldProps,
              renderLabel: (): JSX.Element => (
                <FieldLabelWithDefaultTooltip
                  inputType={inputProp}
                  fieldProps={fieldProps}
                  walkme={walkme}
                  formGroupProps={rawProps}
                  htmlFor={htmlFor}
                  isTooltipVisible={isTooltipVisible}
                  userCanEdit={userCanEdit}
                  popupValidationIconPosition={
                    computedPopupValidationIconPosition
                  }
                  onStatusIconClick={onStatusIconClick as fixMe}
                  positionType={positionType}
                />
              ),
              renderControl: renderControl.bind(null, fieldProps),
              htmlFor,
              value: props.value,
            })}
          </FieldStatusWrapper>
        )}
      </FieldComponent>
    );
  }

  return (
    <FieldComponent {...propsForFormikField}>
      {(fieldProps: FieldProps): JSX.Element => (
        <FieldStatusWrapper<ItemType>
          walkme={walkme}
          inputType={inputProp}
          className={className ?? ''}
          extraLabelProps={extraLabelProps}
          fieldGroupProps={rawProps}
          fieldProps={fieldProps}
          htmlFor={htmlFor}
          isTooltipVisible={isTooltipVisible}
          renderControl={renderControl}
          userCanEdit={userCanEdit}
          popupValidationIconPosition={computedPopupValidationIconPosition}
          onStatusIconClick={onStatusIconClick as fixMe}
          extraBottomPadding={extraBottomPadding}
          positionType={positionType}
        />
      )}
    </FieldComponent>
  );
};
